(function ($) {
	Drupal.behaviors.frontpage = {
		attach: function(context, settings) {
			
			$('body').addClass('js');
			
			var $uw = $('.user-login-widget');
			$uw.children('.content').prepend('<a class="close" />');
			$uw.find('.close').bind('click',function(){
				$uw.toggleClass('open');
			});
			$uw.children('h4').bind('click',function(){
				$uw.toggleClass('open');
			});
			
			$('#simplenews-block-form-5 input[type=text]').focus(function(){
				if($(this).val() == 'mijn@email.adres') {
					$(this).val('');
				}
			})

		}
	}
}(jQuery));